/*!
 *   Insoft S.r.l
 *   Author: Luca Capitoli
 *	 Website: Insoft <https://www.insoft.it>
 */

@backgroundDark: #282d48;
@colorPrimaryDark: #605ca8;
@colorPrimaryLight: #95d9eb;
@colorAccentDark: #f3c6b8;
@colorAccentLight: #e592a2;
@grey: #ecf0f5;

html,
body {
    height: auto !important;
    font-size: 16px;
}

a:hover,
a:focus {
    text-decoration: none;
}

.login-page {
    height: 0%;
}

.error-page-parent {
    width: 100%;
    height: 100%;
    background-color: #222d32;
    position: absolute;
}

.error-page {
    background-color: #222d32;
    padding-top: 30px;
    margin: 0 auto 0 auto;
}

.error-page-message {
    color: #ffffff;
    font-weight: bold;
    font-size: 200%;
    padding-top: 30px;
    margin: 0 auto 0 auto;
    text-align: center;
}

.error-page-select {
    text-align: center;
    padding-top: 2%;
}

.box-title {
    display: block !important;
}

.h3,
h3 {
    font-size: 24px !important;
}

/*
.h2,
h2 {
    font-size: 30px !important;
}
*/

label {
    font-weight: 400 !important;
}

.custom-file-upload {
    cursor: pointer !important;
}

.display-errors input:invalid,
.display-errors textarea:invalid,
.display-errors input.invalid,
.display-errors textarea.invalid {
    color: #dd4b39;
    border-color: #dd4b39;
    box-shadow: none;
}

// #logout-button {
//     position: relative;
//     display: block;
//     padding: 10px 15px;
//     height: 50px;
// }

// #logout-button>button[type=submit] {
//     padding: 5px 15px;
//     background: transparent;
//     border: 0 none;
//     color: white;
//     cursor: pointer;
// }

// #logout-button>button[type=submit]:focus {
//     outline: none;
// }

// #logout-button:hover {
//     background: rgba(0, 0, 0, 0.1);
// }

/* Utilizzato quando non c'è spazio tra icona e testo */

.separate-to-text {
    margin-right: 5px;
}

.fix-bootstrap-input-height {
    height: 34.4px;
}

.category-top-margin {
    margin-top: 40px
}

.button-margin {
    margin: .5em
}

.navbar-brand {
    padding: 5px;
}

/*Usato nella landing-page dopo aver fatto il downgrade di AdminLTE alla 2.3.11*/

.overlay-full-page {
    position: fixed !important;
}

/* Used after send a form */

.year-selection {
    margin-top: 50px;
    margin-bottom: 50px;
}

// .array-item>div {
//     margin-bottom: 100px;
// }

img.news-preview {
    width: 100px;
    height: auto;
}

img.staff-preview {
    width: 120px;
    height: auto;
    box-shadow: 5px 5px 2.5px lightgray;
}

.radio-area-height {
    min-height: 8em;
}

.form-group.no-title>fieldset:first-child>legend:first-child {
    display: none
}

.form-group.field.field-array.no-title div.array-item div.form-group.field.field-object:first-child>fieldset:first-child>legend:first-child {
    display: none;
}

.parent-logos-container {
    height: 100px;
}

.logos-container {
    float: right;
}

.logo-image {
    max-height: 100px;
}

.switch-label {
    margin-right: 1em;
}

.news-item a {
    color: inherit;

    .link-button {
        color: white;
    }
}

#totem-container {
    width: 100%;
    background-color: @grey;

    h2 {
        text-align: center;
    }

    .tabs-container {
        text-align: center;
        background-color: @backgroundDark;
        clear: both;
        overflow: hidden;

        .tab {
            width: 33.333%;
            padding: 15px;
            background-color: @backgroundDark;
            color: white;
            border-bottom: 4px solid @backgroundDark;
            float: left;
        }

        .active {
            border-bottom: 4px solid @colorAccentLight;
            color: @colorAccentLight;
        }
    }

    .left-container {
        background: @grey;
        float: left;
        width: 30%;
        overflow: hidden;
        overflow-y: scroll;
        border-right: 1px solid fade(black, 20%);

        .top-section-title {
            padding: 5px;
        }

        ul.children-list {
            position: relative;
            overflow: hidden;
            max-height: 100%;
            padding: 10px;

            li.list-item {
                padding: 7px;
                font-size: 1.8em;
                border-bottom: 1px solid fade(black, 20%);
                list-style-type: none;

                a {
                    color: @backgroundDark;
                }

                &.active {
                    background-color: @colorAccentLight;
                }

                p {
                    margin: 4px;
                }
            }
        }
    }

    .right-container {
        background: url('../img/diary_background_texture.jpg');
        float: right;
        width: 70%;
        overflow: hidden;
        overflow-y: scroll;

        .child-diary {
            position: relative;
            overflow: hidden;
            max-height: 100%;
            padding: 15px;
        }
    }
}

#news-parent {
    img {
        width: 80%;
    }
}

.news-container {
    padding-top: 30px;
}

.news-wrapper {
    overflow: hidden;

    .left-area {
        text-align: center;
    }

    .right-area {
        text-align: left;
    }

    img {
        width: 80%;
    }

    .modal {
        text-align: center;

        .close {
            color: white;
            opacity: .9;
        }

        .modal-body {
            overflow-y: auto;

            img {
                width: 80%;
            }
        }
    }
}

.footer-row {
    margin-top: .5em
}

.page-header-icon img {
    height: 5em;
}

/**
* Colori etichette stati domanda
**/
.bg-maroon {
    background-color: #d81b60 !important;
}

.label-success {
    background-color: #00a65a !important;
}

.label-warning {
    background-color: #f39c12 !important;
}

.bg-gray-active {
    color: #000;
    background-color: #b5bbc8 !important;
}

.label-danger {
    background-color: #dd4b39 !important;
}

.label-info {
    background-color: #00c0ef !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall.css-1e7hrk5-MuiTableCell-root {
    padding: 4px 2px 4px 2px;
    font-size: 13px;
}

/* Customizations for scheduler */

.k-scheduler-row:has(div.k-scheduler-times-all-day) {
    display: none;
}

.k-animation-container {
    z-index: 10003 !important; // set the desired value based on the application requirements
}




/* Centratura dello spinner nella pagina */
.spinner-container {
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Stili per lo spinner */
    .spinner {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        border-top-color: @colorPrimaryDark;
        animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}